@import './base/bootstrap.scss';
@import './base/bootstrap-extended.scss';
@import './base/colors.scss';
@import './base/components.scss';

// Themes
@import './base/themes/dark-layout.scss';
@import './base/themes/bordered-layout.scss';
@import './base/themes/semi-dark-layout.scss';
// @import './base/custom-rtl.scss';

// React Specific
@import 'react/index';
.row {
    --bs-gutter-x: 1rem;
}

.loader-component {
    background-color: rgb(248, 248, 248, 60%);
    position: fixed;
    z-index: 1000000;
}

.dark-layout {
    .loader-component {
        background-color: rgb(22, 29, 49, 60%);
    }
}

.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
}
.my-masonry-grid_column {
    padding-left: 20px; /* gutter size */
    background-clip: padding-box;
    // margin-right: 1px;
}
// .dragging {
//     opacity: 0.5;
// }

.mobile-input {
    padding: 0px 0px 10px 10px !important;
}
.indicator {
    display: inline-block;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    margin-right: 10px; /* Adjust spacing between indicator and text */
}
.high-indicator {
    background-color: #f62b2b;
}

.low-indicator {
    background-color: #28a828;
}

.medium-indicator {
    background-color: #ff7800;
}
.validation-error {
    margin-top: 0.25rem;
    font-size: 1rem;
    color: #ea5455;
}

.custom-kanban-header {
    color: white;
    border-radius: 3px 3px 0 0;
    padding: 10px 15px;
    /* min-height: 6.5rem; */
    box-shadow: none;
    box-sizing: border-box;
    background: #005084;
    border: 1px solid #005084;
    border-top: 0;
    vertical-align: middle;
    width: 100%;
    overflow: hidden;
}

.board-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.lead-board {
    border-right: 1px solid #ccc;
    margin-bottom: 10px;
}
@media (max-width: 575.98px) {
    .lead-board {
        border-right: none;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .lead-board {
        border-right: none;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .lead-board-sm-xs {
        border-right: 1px solid #ccc;
        margin-bottom: 10px;
    }
}

.lead-details {
    font-size: 14px;
    font-weight: bold;
    color: '#1f2022';
}

.lead-score {
    border: 1px solid #cfdae2;
    // background-color: #e9eeff;
}

.progress-bars {
    color: #005084;
}
.padding-custom {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px !important;
}

.days-lead {
    padding: 0px !important;
}

.card-padding {
    padding-top: 0px !important;
}
.modal-lg-2 {
    --bs-modal-width: 60%;
}
.modal-xl-2 {
    --bs-modal-width: 80%;
}
.modal-custom-lg {
    --bs-modal-width: 50% !important;
}

.card_active {
    // background-color: #f0f0f0; /* Set your desired active card color */
    // border: 2px solid #405189; /* Set border for active card */
    border-top: 4px solid rgba(47, 100, 142, 0.7) !important;
    border-radius: 5px;
}
.card-inactive {
    border-top: 4px solid #6d6d6d;
    border-radius: 5px;
}

.card-default {
    border-top: 4px solid #fff;
    border-radius: 5px;
}

.card_active_item {
    // border-left: 2px solid #ffbe0b;
    background-color: #e6ecf1;
}
.card-left-panel {
    padding: 10px;
}
.card-booking-card {
    border: 1px solid gray;
    border-radius: 10px;
    // background-color: white;
    box-shadow: 0 3px 5px 0 rgba(155, 152, 152, 0.19), 0 6px 20px 0 rgba(155, 152, 152, 0.19);
}
.card-trip-booking {
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.01), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.custom-padding {
    padding-right: 0px !important;
}

.custom-left-padding {
    padding-left: 0px !important;
}

.custom-flatpickr {
    font-size: 0.85rem; /* Adjust font size */
    // padding: 0.25rem 0.5rem; /* Adjust padding */
    padding: 0.5rem;
}

.toDos-label {
    font-size: 15px;
}

//Account Badge Bulk delete
.account-badge-delete {
    white-space: normal;
    display: block;
    text-align: left;
    line-height: 1.5;
}
.custom-sm-dropdown .btn-sm,
.btn-group-sm > .btn {
    --bs-btn-padding-y: 0.2rem;
    padding-left: 0px;
}
.meeting-event {
    border: 1px solid #7367f01a !important;
    background-color: #eae8fd !important;
    color: #7367f0 !important;
}

.todo-event {
    border: 1px solid #00cfe81f !important;
    background-color: #d9f8fc !important;
    color: #00cfe8 !important;
}

.call-event {
    border: 1px solid #28c76f1a !important;
    background-color: #dff2e7 !important;
    color: #28c76f !important;
}

// legent color css
.legend-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.legend-item {
    display: inline-flex;
    align-items: center;
    margin-right: 20px;
}

.legend-item::before {
    content: '';
    // display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 5px;
}

.meeting-box:before {
    background-color: #7367f0;
}

.call-box::before {
    background-color: #28c76f;
}

.to-dos-box::before {
    background-color: #00cfe8;
}

.fc .fc-daygrid-day-top {
    justify-content: center;
}
.fc .fc-daygrid-day-number {
    font-weight: bold;
}

.fc .fc-daygrid-day.fc-day-today {
    background-color: #fff;
}

.fc-daygrid-event-dot {
    border: 0px !important;
}

.compose-mail-form-field {
    padding: 0.12rem 1.4rem !important;
    border-bottom: 1px solid rgba(34, 41, 47, 0.05) !important;
}

.compose-mail-form-field label {
    font-size: 1rem !important;
}

.compose-mail-form-field {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.compose-mail-form-field {
    padding: 0.12rem 1.4rem !important;
    border-bottom: 1px solid rgba(34, 41, 47, 0.05) !important;
}

.compose-mail-form-field {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.compose-mail-form-field label {
    font-size: 1rem !important;
}

.compose-mail-form-field .form-control,
.compose-mail-form-field .select2-selection--multiple {
    border: 0 !important;
    background-color: transparent !important;
}
.compose-footer-wrapper {
    padding: 0.8rem 1.4rem !important;
}

.compose-footer-wrapper {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
}

.rdw-editor-main {
    min-height: 40rem !important;
    max-height: 40rem !important;
    overflow: auto;
}
.fc-h-event .fc-event-main {
    color: #7367f0;
}

.custom-paperclip-button {
    border: none !important;
}
.custom-report-button {
    border: none !important;
    background-color: transparent !important;
}
.custom-report-button:hover {
    box-shadow: none !important;
}
.custom-report-button:focus {
    background-color: transparent !important;
}
// ** report Css
.report-select .css-1rv6cxx-control {
    border-color: #b7b9b9 !important;
}
.report-form-control {
    border: 1px solid #b7b9b9 !important;
}
.filter-color {
    color: #005084 !important;
}

/* styles.css */

.workflow-container {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.when-icon {
    background-color: #2b3e8c;
    color: #fff;
    width: 110px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: bold;
    font-size: 16px;
    position: relative;
    z-index: 1;
}
.circle-icon {
    background-color: #2b3e8c;
    color: #fff;
    width: 130px;
    height: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: bold;
    font-size: 16px;
    position: relative;
    z-index: 1;
}

.condition-button {
    color: #692db8;
    margin: 20px;
    width: 110px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: bold;
    font-size: 16px;
    position: relative;
    z-index: 1;
}

.connection-line {
    height: 3px;
    background-color: #2b3e8c;
    flex-grow: 15;
    margin: 27px 0px;
    position: relative;
    top: 25px;
    z-index: 0;
}

.connection-line-vertical {
    width: 3px;
    background-color: #2b3e8c;
    position: absolute;
    z-index: 0;
    top: 0px;
    bottom: 0px;
    margin: auto;
    left: 50%;
    transform: translateX(-50%);
}

.card-body-custom {
    padding: 17px;
    border: 1px solid #dcdcdc;
    border-radius: 7px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.button-next {
    // background-color: #d9e5fc;
    border: none;
    color: #000;
    padding: 10px 20px;
    border-radius: 5px;
}

.automation-col {
    padding: 0px 0px 0px 0px;
}
///ne mail
html .content-modal {
    padding: 0;
    position: relative;
    transition: 300ms ease all;
    backface-visibility: hidden;
    min-height: calc(100% - 3.35rem);
}
.my-datagrid .dx-datagrid {
    cursor: pointer;
}

.instant-actions-container {
    position: relative;
    display: inline-block;
    z-index: 1; /* Set a higher z-index for the container */
}

#instantActionsMenu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    padding: 10px;
    display: block;
    z-index: 2; /* Set a higher z-index for the menu */
}

#instantActionsButton {
    cursor: pointer;
}

/* Add these styles to your CSS file */
.menu {
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    top: 50px; /* Adjust as needed to position the menu correctly */
    z-index: 1000; /* Ensure the menu is above other elements */
    width: 160px; /* Adjust as needed */
    padding: 10px 0px;
    border-radius: 4px;
}

.menu-option {
    padding: 5px 10px;
    font-size: 13px;
    color: #333;
    cursor: pointer;
    position: relative;
}

.menu-option:hover {
    // background-color: #f0f0f0;
    background-color: #e6ecf1;
}

.submenu {
    display: none;
    position: absolute;
    left: 100%;
    top: 0;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 160px; /* Adjust as needed */
    padding: 10px 0px;
    border-radius: 4px;
    z-index: 1000; /* Ensure the submenu is above other elements */
}

.menu-option:hover .submenu {
    display: block;
}

.submenu-option {
    padding: 5px 10px;
    font-size: 13px;
    color: #333;
    cursor: pointer;
}

.submenu-option:hover {
    background-color: #e6ecf1;
}
.oneOneZa-detail-card {
    background-color: #f6f6f8;
}
.custom-invalid .was-validated .form-control:invalid,
.form-control.is-invalid {
    background-image: none !important;
    padding-right: 1.5em !important;
}
.custom-invalid .was-validated .form-control:invalid,
.form-control.is-invalid {
    background-image: none !important;
    padding-right: 1.5em !important;
}

.oneOneZa-detail-card {
    background-color: #f6f6f8;
}
.custom-invalid .was-validated .form-control:invalid,
.form-control.is-invalid {
    background-image: none !important;
    padding-right: 1.5em !important;
}

//call button
.call-confirm-button {
    color: #005084;
}
// reminder
.reminder-input {
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: #efefef;
}
.circular-index {
    width: 30px;
    height: 30px;
    border: 2px solid #005084;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #005084;
    font-weight: bold;
    position: relative;
}

.circular-index.has-top-line::before {
    content: '';
    width: 2px;
    height: 10px;
    background-color: #d1cece;
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
}

.circular-index.has-bottom-line::after {
    content: '';
    width: 2px;
    height: 10px;
    background-color: #d1cece;
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
}
.automation-select .select__control.select__control--is-disabled,
.react-select__control.select__control--is-disabled {
    border-color: #005084 !important;
}
.select__control.select__control--is-disabled .select__single-value,
.react-select__control .select__single-value {
    color: #005084 !important;
    font-weight: 600;
}
.automation-input.form-control {
    border-color: #ededed;
}
.automation-input.form-control:disabled {
    border-color: #005084 !important;
}
.automation-input.form-control:disabled {
    background-color: #efefef !important;
    color: #005084 !important;
    font-weight: 600;
}
//oucome
.no-data-image {
    width: 500px;
    height: auto;
    opacity: 0.6;
}
.no-data-image-reminder {
    width: 400px;
    height: auto;
    opacity: 0.6;
}
.no-data-mail-image {
    width: 360px;
    height: auto;
    opacity: 0.6;
}

.no-data-custom {
    width: 400px;
    height: auto;
    opacity: 0.6;
}
.no-data-open-activity {
    width: 225px;
    height: auto;
    opacity: 0.6;
}
.custom-form-rightBorder {
    border-right: 1px solid #b2b2b2;
    padding-right: 30px;
}
.custom-form-rightCol {
    padding-left: 30px;
}

//lead Profile top button
@media (max-width: 476px) {
    //lead
    .lead-avatar-sm {
        display: none;
    }
    .lead-call-button {
        width: 100%;
        margin-bottom: 10px;
    }
    .lead-send-mobile-button {
        margin-bottom: 10px;
    }
    .lead-send-mail-button {
        margin-bottom: 10px;
    }
    .lead-convert-button {
        margin-bottom: 10px;
    }
    .lead-edit-button {
        margin-bottom: 10px;
    }
    .no-data-image {
        width: 300px;
    }
    .no-data-custom {
        width: 300px;
    }
    //customer
    .customer-avatar-mb {
        margin-bottom: 10px;
    }
    .custom-form-rightBorder {
        border-right: 0px;
        padding-right: 0px;
    }
    .custom-form-rightCol {
        padding-left: 0px;
    }
}

@media (min-width: 477px) and (max-width: 556px) {
    .lead-call-button {
        margin-bottom: 10px;
    }
    .lead-send-mobile-button {
        margin-bottom: 10px;
    }
    .lead-send-mail-button {
        margin-bottom: 10px;
    }
    .lead-convert-button {
        margin-bottom: 10px;
    }
    .lead-edit-button {
        margin-bottom: 10px;
    }
    .no-data-image {
        width: 300px;
    }
    .no-data-custom {
        width: 300px;
    }
    //customer - contact
    .customer-avatar-mb {
        margin-bottom: 10px;
    }
    .custom-form-rightBorder {
        border-right: 0px;
        padding-right: 0px;
    }
    .custom-form-rightCol {
        padding-left: 0px;
    }
}
@media (min-width: 557px) and (max-width: 575px) {
    .lead-call-button {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .lead-send-mobile-button {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .lead-send-mail-button {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .lead-convert-button {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .lead-edit-button {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .no-data-image {
        width: 300px;
    }
    .no-data-custom {
        width: 300px;
    }
    .custom-form-rightBorder {
        border-right: 0px;
        padding-right: 0px;
    }
    .custom-form-rightCol {
        padding-left: 0px;
    }
}
@media (min-width: 575px) and (max-width: 767px) {
    .custom-form-rightBorder {
        border-right: 0px;
        padding-right: 0px;
    }
    .custom-form-rightCol {
        padding-left: 0px;
    }
}
@media (min-width: 576px) and (max-width: 991px) {
    .lead-call-button {
        margin-bottom: 10px;
    }
    .lead-send-mobile-button {
        margin-bottom: 10px;
    }
    .lead-send-mail-button {
        margin-bottom: 10px;
    }
    .lead-convert-button {
        margin-bottom: 10px;
    }
    .lead-edit-button {
        margin-bottom: 10px;
    }
}
@media (min-width: 992px) and (max-width: 1224px) {
    .lead-call-button {
        margin-bottom: 10px;
    }
    .lead-send-mobile-button {
        margin-bottom: 10px;
    }
    .lead-send-mail-button {
        margin-bottom: 10px;
    }
    .lead-convert-button {
        margin-bottom: 10px;
    }
    .lead-edit-button {
        margin-bottom: 10px;
    }
}

@media (min-width: 1225px) and (max-width: 1700px) {
    .lead-call-button {
        margin-bottom: 10px;
    }
    .lead-send-mobile-button {
        margin-bottom: 10px;
    }
    .lead-send-mail-button {
        margin-bottom: 10px;
    }
    .lead-convert-button {
        margin-bottom: 10px;
    }
    .lead-edit-button {
        margin-bottom: 10px;
    }
}

//operator
.border-operator-end {
    border-right: 1px solid #ccc;
}
@media (max-width: 991px) {
    .border-operator-end {
        border-right: none;
    }
}
@media (max-width: 450px) {
    .op-button {
        margin-top: 10px !important;
    }
}
@media (max-width: 778px) {
    .custom-btn-group-responsive {
        margin-top: 10px !important;
    }
}
@media (max-width: 574px) {
    .custom-tab-responsive {
        margin-top: 10px !important;
    }
}
@media (max-width: 453px) {
    .button-group-responsive {
        margin-top: 10px !important;
    }
}
@media (max-width: 492px) {
    .customer-group-responsive {
        margin-top: 10px !important;
    }
}

@media (max-width: 367px) {
    .record-responsive {
        margin-top: 10px !important;
    }
}

//todo button
@media (max-width: 444px) {
    .custom-todoButton {
        margin-top: 10px !important;
    }
}
@media (max-width: 572px) {
    .custom-todoTask {
        margin-top: 10px !important;
    }
}
//schedule  call button
@media (max-width: 426px) {
    .custom-reorderButton {
        margin-bottom: 10px !important;
        margin-top: 10px !important;
    }
}

// lead report
@media (min-width: 1650px) {
    .groupBy-end {
        justify-content: end !important;
    }
    .isDate-groupBy-end {
        justify-content: end !important;
    }
}

@media (min-width: 992px) and (max-width: 1650px) {
    .groupBy-end {
        margin-top: 10px !important;
    }
    .isDate-groupBy-end {
        justify-content: end !important;
    }
}

@media (max-width: 768px) {
    .report-top {
        margin-top: 10px !important;
    }
}
@media (min-width: 768px) and (max-width: 993px) {
    .custom-apply-top {
        margin-top: 10px !important;
    }
}

@media (min-width: 1440px) and (max-width: 1650px) {
    .custom-width-selected {
        width: 10.333333% !important;
    }
}
@media (min-width: 1200px) and (max-width: 1440px) {
    .custom-day-width {
        width: 12.66666667% !important;
    }
}

//Deal Report
@media (min-width: 769px) and (max-width: 1440px) {
    .custom-deal-top {
        margin-top: 10px !important;
    }
}
@media (min-width: 769px) and (max-width: 991px) {
    .custom-deals-apply-top {
        margin-top: 10px !important;
    }
}
@media (min-width: 992px) and (max-width: 1440px) {
    .custom-deal-apply-top {
        margin-top: 10px !important;
    }
}
@media (min-width: 1440px) and (max-width: 1610px) {
    .custom-date-width {
        width: 10.333333% !important;
    }
}

@media (max-width: 622px) {
    .custom-add-responsive {
        margin-top: 10px !important;
    }
}
//dashboard
.custom-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.custom-card:hover {
    transform: translateY(-5px);
}

//
.fb-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}
.left-border-d {
    border-right: 1px solid #dae1e7;
}

.dashboard-card {
    font-size: 30px;
    font-weight: 550;
    color: #333333;
    // font-family:'sans-serif';
}

// Custom Activity
.custom-activity-key {
    color: black;
    font-weight: 500;
}
.custom-activity-value {
    color: gray;
    // font-weight: 500;
    opacity: 0.7;
}
.custom-activity-border {
    border: 1px solid #d8d6de;
}

//Profile Activity History
.icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    background-color: #cfdae2;
    border-radius: 50%;
    margin-right: 10px;
}
.activity-history-table-bg {
    background-color: #00508433 !important;
    color: #005084 !important;
}
.min-width-table {
    // min-width: 635px;
}
.icon-color {
    color: #005084;
}

//automation
// .react-flow__node-default.react-flow__node-input{
//     z-index: -1000 !important;
// }
.react-flow__node.react-flow__node-default.selectable {
    // z-index: -1000 !important;
}
.react-flow__node.react-flow__node-default.nopan.selectable {
    z-index: -1011 !important;
}

.react-flow__node.react-flow__node-default.nopan.selected.selectable {
    z-index: -11 !important;
}
.react-flow__node.react-flow__node-default.nodrag.nopan.selectable {
    z-index: -1011 !important;
}
.react-flow__node.react-flow__node-default.nopan.selectable.parent {
    z-index: -1000 !important;
}
.react-flow__node.react-flow__node-default.parent.selectable {
    z-index: -10 !important;
}
.react-flow__edge {
    z-index: -1000 !important;
}
.react-flow .react-flow__edges {
    z-index: -1000 !important;
}

.automation-dropdown-item {
    font-size: 11px;
    font-weight: bolder;
    display: flex;
    align-items: center;
}
.automation-dropdown-item-header {
    font-size: 12px;
    font-weight: bolder;
    margin-left: 10px;
    color: black;
}
// .input-group-text {
//     border-top-right-radius: 0.357rem solid #d8d6de !important;
//     border-bottom-right-radius: 0.357rem solid #d8d6de !important;

// }

//Add Form Button responsive

@media (max-width: 516px) {
    .cancel-button {
        margin-top: 10px !important;
    }
}
@media (max-width: 425px) {
    .reset-button {
        margin-top: 10px !important;
    }
}

//Remove border input
.remove-border {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

//rotating
@keyframes rotating {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
.rotating {
    animation: rotating 1s linear infinite;
    color: #005084 !important;
    border-color: transparent !important;
}
.custom-api-btn {
    margin-top: 20px;
}

@media (min-width: 1440px) {
    .custom-api-btn {
        margin-top: 45px !important;
    }
}

//automation report
.report-badge {
    position: absolute;
    top: -10px;
    right: -7px;
}
